<!--
  -  Copyright (C) Healabs 2022 - All Rights Reserved
  -  Unauthorized copying of this file, via any medium is strictly prohibited
  -  Proprietary and confidential
  -
  -->

<template>
  <v-container fluid class="h-100 pa-0 white">
    <v-row no-gutters class="fill-height" justify="center" align="center">
      <v-col cols="12">
        <v-form ref="cgu" lazy-validation @submit.prevent="submit">
          <v-card flat :color="$vuetify.breakpoint.mdAndDown ? 'white' : 'transparent'" :class="$vuetify.breakpoint.mdAndUp ? 'mx-5 pa-5' : ''">
            <v-card-title class="pt-0 font-alt">Conditions générales d’utilisation</v-card-title>
            <v-card-text class="text-center">
              <div class="iframe-wrapper">
                <iframe :src="pdfFile" type="application/pdf" />
              </div>
            </v-card-text>
            <v-card-actions>
              <v-checkbox v-model="checkbox" class="mr-5">
                <template v-slot:label>
                  <div>
                    J'ai lu et j'accepte les
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <a
                          target="_blank"
                          :href="pdfFile"
                          @click.stop
                          v-on="on"
                        >
                          Conditions générales d’utilisation
                        </a>
                      </template>
                      Ouvrir dans une nouvelle fenêtre
                    </v-tooltip>
                  </div>
                </template>
              </v-checkbox>
              <v-spacer />
              <v-btn type="submit" small color="primary" :loading="submitting" :disabled="!checkbox || submitting">Confirmer</v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import {acceptCGU} from '@/modules/auth/api'
  export default {
    name: 'AcceptConditions',
    data: () => ({
      checkbox: false,
      submitting: false,
      pdfFile: 'cgu/cgu_fr_ma_pread.pdf'
    }),
    methods: {
      async submit() {
        if (!this.$refs.cgu.validate()) {
          return
        }

        this.submitting = true
        try {
          await acceptCGU(this.$auth.user.id)
          this.$auth.user.cguValidationDate = new Date().toISOString()
          try {
            await this.$router.push({name: 'home'})
          } catch (e) {
            if(!e._isRouter) {
              throw e
            }
          }
        } catch (e) {
          this.submitting = false
          this.$root.$emit('snackbar:open', {
            snackbar: () => import('@/modules/core/components/Snackbar'),
            type: 'error',
            title: 'Une erreur est survenue'
          })
          throw e
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .iframe-wrapper {
    position: relative;
    width: 100%;
    height: 70vh;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 1px solid #ccc;
    }
  }
</style>
